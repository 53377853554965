import React from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import { fetchTickets } from "../../redux/core";

// utils

// components
import LoadingState from "../../components/LoadingState";
import CreateEditTickets from "../../components/containers/modals/CreateEditTickets";
import TicketsTable from "./components/tables/TicketsTable";

type T_Ticket = {
  idticket?: number;
  idUsuario: string;
  imagenTicket: string;
  codigoMarca: number;
  nombreMarca: string;
  codigoPais: number;
  nombrePais: string;
  fechaCreacion: string;
  fecha: string;
  importeTicket: number;
  importeRecompensa: number;
  estado: string;
  referenciaTicket: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  porcentajeRecompensa: number;
  comentarios?: string;
};

const Tickets = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [typeCall, setTypeCall] = React.useState<string>("Crear");
  const [selectedTicket, setSelectedTicket] = React.useState<T_Ticket>({
    idticket: 0,
    idUsuario: "",
    imagenTicket: "",
    codigoMarca: 0,
    nombreMarca: "",
    codigoPais: 0,
    nombrePais: "",
    fechaCreacion: "",
    fecha: "",
    importeTicket: 0,
    importeRecompensa: 0,
    estado: "",
    referenciaTicket: "",
    poblacionEstablecimiento: "",
    direccionEstablecimiento: "",
    codigoPostal: "",
    nombreLegalEmpresa: "",
    numeroLegalEmpresa: "",
    porcentajeRecompensa: 0,
    comentarios: "",
  });

  // redux
  const dispatch = useDispatch();
  const { tickets, isLoading } = useSelector((state: any) => state.core);

  const handleRefreshTicket = (page: number) => {
    dispatch(fetchTickets(page));
  };

  const handleUpdateTicket = (ticketData: T_Ticket) => {
    setShowModal(true);
    setTypeCall("Editar");
    setSelectedTicket(ticketData);
  };

  const handleVerTicket = (ticketData: T_Ticket) => {
    setShowModal(true);
    setTypeCall("Ver");
    setSelectedTicket(ticketData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    dispatch(fetchTickets());
  }, []);

  return (
    <div>
      <CreateEditTickets
        isOpen={showModal}
        handleClose={handleCloseModal}
        typeCall={typeCall}
        selectedTicket={selectedTicket}
      />
      <KTCard>
        <div className="card-header">
          <div className="card-title">Tickets</div>
          {/* <div className="card-toolbar">
            <Button onClick={handleRefreshTicket}>Actualizar</Button>
          </div> */}
        </div>
        <KTCardBody className="py-4 position-relative">
          {isLoading ? (
            <LoadingState />
          ) : (
            <TicketsTable
              tableData={tickets}
              handleView={handleVerTicket}
              handleEdit={handleUpdateTicket}
              handleRefresh={handleRefreshTicket}
            />
          )}
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default Tickets;
