import React from "react";
import { Dropdown, Pagination } from "react-bootstrap";

import { ticketsColumn } from "./columns/_columns";

type T_ticket = {
  idticket?: number;
  idUsuario: string;
  imagenTicket: string;
  codigoMarca: number;
  nombreMarca: string;
  codigoPais: number;
  nombrePais: string;
  fechaCreacion: string;
  fecha: string;
  importeTicket: number;
  importeRecompensa: number;
  estado: string;
  referenciaTicket: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  porcentajeRecompensa: number;
  comentarios?: string;
};

interface Iprops {
  tableData: {
    page: number;
    totalPages: number;
    totalData: number;
    data: Array<T_ticket>;
  };
  handleEdit: (data: T_ticket) => void;
  handleView: (data: T_ticket) => void;
  handleRefresh: (page: number) => void;
}

const TicketsTable: React.FC<Iprops> = ({
  tableData,
  handleView,
  handleEdit,
  handleRefresh,
}) => {
  // states
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [tickets, setTickets] = React.useState<Array<T_ticket>>();
  // variables

  // hooks

  // functions
  const verifyCanPreviousPage = (current: number) => current > 1;
  const verifyCanNextPage = (current: number) =>
    current === tableData.totalPages;

  // effects
  React.useEffect(() => {
    if (tableData) {
      setTickets(tableData.data);
      setCurrentPage(tableData.page);
    }
  }, [tableData]);

  return (
    <div className="table-responsive">
      <table className="table table-hover table-row-dashed fs-6 gy-5 table-sm dataTable align-middle m-10">
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            {ticketsColumn.map((column: any) => (
              <td key={column.accessor}>{column.Header}</td>
            ))}
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {tickets && tickets.length > 0 ? (
            tickets.map((ticket, index) => (
              <tr key={index}>
                <td>{ticket.idticket}</td>
                <td>{ticket.idUsuario}</td>
                <td>{ticket.referenciaTicket}</td>
                <td>{ticket.importeTicket}</td>
                <td>{ticket.fechaCreacion}</td>
                <td>{ticket.estado}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      Acciones
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          handleView(ticket);
                        }}
                      >
                        Ver
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          handleEdit(ticket);
                        }}
                      >
                        Editar
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="no-records-found">
                No matching records found
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>
              <Pagination>
                <Pagination.First
                  onClick={() => {
                    handleRefresh(1);
                  }}
                  disabled={!verifyCanPreviousPage(currentPage)}
                />
                <Pagination.Prev
                  onClick={() => {
                    const prevPage = currentPage - 1;
                    if (prevPage >= 1) {
                      handleRefresh(prevPage);
                    }
                  }}
                  disabled={!verifyCanPreviousPage(currentPage)}
                />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next
                  onClick={() => {
                    const nextPage = currentPage + 1;
                    if (nextPage < tableData.totalPages) {
                      handleRefresh(nextPage);
                    }
                  }}
                  disabled={verifyCanNextPage(currentPage)}
                />
                <Pagination.Last
                  onClick={() => {
                    const lastPage = tableData.totalPages;
                    handleRefresh(lastPage);
                  }}
                  disabled={verifyCanNextPage(currentPage)}
                />
              </Pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TicketsTable;
