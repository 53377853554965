import React from "react";
import { Container, Row, Col, FormControl, InputGroup } from "react-bootstrap";

// redux
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "../../redux/app";

// services
import { core } from "../../services";

// components
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import ModalLoadingState from "../../components/ModalLoadingState";

interface Iticket {
  idUsuario: string;
  codigoMarca: number;
  codigoPais: number;
  fechaCreacion: string;
  fecha: string;
  importeTicket: number;
  importeRecompensa: number;
  estado: string;
  referenciaTicket: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  comentarios?: string;
}

type Tstate = "rechazado" | "validada" | "pendiente";

const ShoopingRewards = () => {
  // states
  const [allTickets, setAllTickets] = React.useState<Array<Iticket>>([]);

  // hooks
  const dispatch = useDispatch();

  // functions
  const getAmountOfTicketsByState = (state: Tstate) => {
    const tickets: Array<Iticket> = allTickets.filter(
      (el: Iticket) => el.estado === state
    );

    return tickets.length;
  };

  const getTotalOfImporteTickets = () => {
    const filteredValidated = allTickets.filter(
      (el: Iticket) => el.estado === "validada"
    );
    const total = filteredValidated.reduce((total: number, val: Iticket) => {
      return (total = total + val.importeTicket);
    }, 0);

    return total;
  };

  // fetch all tickets
  React.useEffect(() => {
    const fetchAllTickets = async () => {
      try {
        dispatch(startLoading());
        const response: Array<Iticket> = await core.tickets.getTickets();
        setAllTickets(response);
      } catch (error) {
        setAllTickets([]);
      } finally {
        dispatch(endLoading());
      }
    };
    fetchAllTickets();
  }, []);

  return (
    <>
      <ModalLoadingState />
      <div
        className="d-flex flex-column flex-center bgi-no-repeat bgi-size-cover bgi-position-center rounded-top px-9 pt-20 pb-10"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/misc/pattern-1.jpg"
          )}')`,
        }}
      >
        <h1
          className="text-white fw-bold mb-3"
          style={{
            fontSize: "3.5rem",
          }}
        >
          Estadisticas
        </h1>
      </div>

      <Container className="pt-10">
        <h3>Tickets</h3>
        {allTickets.length > 0 ? (
          <Row>
            <Col xs={6}>
              <InputGroup>
                <InputGroup.Text>Total tickets</InputGroup.Text>
                <FormControl value={allTickets?.length} disabled />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <InputGroup.Text>Total tickets rechazados</InputGroup.Text>
                <FormControl
                  value={getAmountOfTicketsByState("rechazado")}
                  disabled
                />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <InputGroup.Text>Total tickets validados</InputGroup.Text>
                <FormControl
                  value={getAmountOfTicketsByState("validada")}
                  disabled
                />
              </InputGroup>
            </Col>
            <Col xs={6}>
              <InputGroup>
                <InputGroup.Text>Total tickets pendientes</InputGroup.Text>
                <FormControl
                  value={getAmountOfTicketsByState("pendiente")}
                  disabled
                />
              </InputGroup>
            </Col>

            <Col xs={6}>
              <InputGroup>
                <InputGroup.Text>
                  Total importe tickets validados
                </InputGroup.Text>
                <FormControl value={getTotalOfImporteTickets()} disabled />
              </InputGroup>
            </Col>
          </Row>
        ) : null}
      </Container>
    </>
  );
};

export default ShoopingRewards;
