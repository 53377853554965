import { core } from "../app/services";
import * as fns from "date-fns";

interface Iticket {
  idticket?: number;
  fecha: string;
  imagenTicket: string;
  fechaCreacion: string;
  codigoMarca: number;
  nombreMarca: string;
  codigoPais: number;
  nombrePais: string;
  idUsuario: string;
  importeTicket: number;
  importeRecompensa: number;
  referenciaTicket: string;
  estado: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  porcentajeRecompensa: number;
  estadoBase?: string;
  fechaTicketBase?: string;
  comentarios?: string;
}

const referenceIsUnique = async (ticketId: number, references: string) => {
  try {
    const collectionTickets = await core.tickets.getTickets();
    const mapTickets: Array<{
      idticket: number;
      referenciaTicket: string;
    }> = collectionTickets.map((ticket: any) => {
      return {
        idticket: ticket.idticket,
        referenciaTicket: ticket.referenciaTicket,
      };
    });

    for (let index = 0; index < mapTickets.length; index++) {
      if (ticketId !== mapTickets[index].idticket) {
        if (references === mapTickets[index].referenciaTicket) {
          // console.log(
          //   "es igual",
          //   references,
          //   mapTickets[index].referenciaTicket
          // );
          return false;
        }
      }
    }

    return true;
  } catch (error) {
    throw new Error("error al buscar los tickets");
  }
};

export const verifyTicketDate = (
  ticketDateString: string,
  ticketCreationDate: string
) => {
  // fecha del ticket
  const ticketDate = new Date(ticketDateString);
  // fecha hoy
  // const ticketDateBase = new Date();
  const creationDate = new Date(ticketCreationDate);
  // fecha hoy mas 15 dias
  const limitDate = fns.subDays(creationDate, 15);

  if (fns.isAfter(ticketDate, limitDate)) {
    return true;
  } else {
    return false;
  }
};

const getTotalPointsByUser = async (userId: string) => {
  try {
    const ticketsByUser: Array<Iticket> = await core.tickets.getTicketsByUser(
      userId
    );

    // verificar solamente los del actual mes
    const currentDate = new Date();
    const filtersThisMonthTickets: Array<Iticket> = ticketsByUser.filter(
      (el: Iticket) => {
        const isCurrentDate = fns.isSameMonth(currentDate, new Date(el.fecha));

        if (isCurrentDate) {
          return true;
        }

        return false;
      }
    );

    // verificar solo los validados
    const filtersTicketsValidated: Array<Iticket> =
      filtersThisMonthTickets.filter((el: Iticket) => {
        if (el.estado === "validada") {
          return true;
        }

        return false;
      });

    const onlyImporteTickets = filtersTicketsValidated.map(
      (el: Iticket) => el.importeRecompensa
    );
    const totalImporteTickets = onlyImporteTickets.reduce(
      (total: number, num: number) => total + num,
      0
    );
    return totalImporteTickets;
  } catch (error) {
    throw new Error("hubo un error al buscar los puntos");
  }
};

const formatDateToMariaDBDate = (stringDate: string) => {
  const toDate = new Date(stringDate);
  const toString = fns.format(toDate, "yyyy-MM-dd hh:mm:ss");
  return toString;
};

export const handleUpdateTicket = async (data: Iticket) => {
  if (data.estadoBase !== data.estado) {
    // console.log("el estado es diferente");

    // verificar la fecha
    const isOnDateTicket = verifyTicketDate(data.fecha, data.fechaCreacion);
    // verificar los puntos
    const amountOfPoints = await getTotalPointsByUser(data.idUsuario);
    // verificar si es unico
    const isUniqueReference = await referenceIsUnique(
      Number(data.idticket),
      data.referenciaTicket
    );

    const points: Number = amountOfPoints + data.importeRecompensa;
    const isMaxPoints: Boolean = +points <= 120;

    if (data.estado === "validada") {
      // console.log("validando");

      // todo es correcto
      if (isOnDateTicket && isUniqueReference && isMaxPoints) {
        // console.log("es unico");
        try {
          const addPuntosPayload = {
            idCliente: data.idUsuario,
            cantidad: data.importeRecompensa,
            concepto: `REWARD-${data.nombreMarca}-${fns.format(
              new Date(data.fecha),
              "dd-MM-yyyy"
            )}`,
            tipo: 2,
          };

          // enviar los puntos al usuario
          const res = await core.addPuntos(addPuntosPayload);

          console.log(res);

          // payload
          const payload: Iticket = {
            ...data,
            fechaCreacion: formatDateToMariaDBDate(data.fechaCreacion),
            fecha: formatDateToMariaDBDate(data.fecha),
          };

          // guardar en base de datos.
          await core.tickets.updateTicket(Number(data.idticket), payload);

          // enviar informacion
          return {
            updated: true,
            unique: true,
            validated: true,
          };
        } catch (error) {
          return {
            updated: false,
            unique: false,
            validated: false,
          };
        }
      }
      // si no, cambiar estado a pendiente y solamente guardar.
      else {
        // console.log("no es unico");
        try {
          // payload
          const payload: Iticket = {
            ...data,
            fechaCreacion: formatDateToMariaDBDate(data.fechaCreacion),
            fecha: formatDateToMariaDBDate(data.fecha),
            estado: "pendiente",
          };

          // guardar en base de datos.
          await core.tickets.updateTicket(Number(data.idticket), payload);

          // enviar informacion
          const referenceIsNotUniqueMessage = `${
            !isUniqueReference
              ? "Referencia del ticket ya existe"
              : "la referencia es correcta"
          }`;

          const limitDate = fns.subDays(new Date(), 15);
          const limitDateString = fns.format(limitDate, "dd-MM-yyyy");
          const isNotOnDateMessage = `${
            !isOnDateTicket
              ? `La fecha tiene que ser despues del ${limitDateString}`
              : "La fecha es correcta"
          }`;

          const isCurrentMonthMaxPointMessage = `${
            !isMaxPoints
              ? "los puntos son mayores a 1000"
              : "los puntos son menores a 1000"
          }`;

          const mensaje = `${referenceIsNotUniqueMessage}, ${isNotOnDateMessage}, ${isCurrentMonthMaxPointMessage}`;
          return {
            updated: true,
            unique: false,
            validated: false,
            message: mensaje,
          };
        } catch (error) {
          return {
            updated: false,
            unique: false,
            validated: false,
          };
        }
      }
    }

    if (data.estado === "pendiente") {
      try {
        const payload: Iticket = {
          ...data,
          fechaCreacion: formatDateToMariaDBDate(data.fechaCreacion),
          fecha: formatDateToMariaDBDate(data.fecha),
        };
        await core.tickets.updateTicket(Number(data.idticket), payload);
        return {
          updated: true,
          unique: false,
          validated: false,
        };
      } catch (error) {
        return {
          updated: false,
          unique: false,
          validated: false,
        };
      }
    }

    if (data.estado === "rechazado") {
      try {
        const payload: Iticket = {
          ...data,
          fechaCreacion: formatDateToMariaDBDate(data.fechaCreacion),
          fecha: formatDateToMariaDBDate(data.fecha),
        };
        await core.tickets.updateTicket(Number(data.idticket), payload);
        return {
          updated: true,
          unique: false,
          validated: false,
        };
      } catch (error) {
        return {
          updated: false,
          unique: false,
          validated: false,
        };
      }
    }
  } else {
    // console.log("el estado es igual");
    try {
      const payload: Iticket = {
        ...data,
        fechaCreacion: formatDateToMariaDBDate(data.fechaCreacion),
        fecha: formatDateToMariaDBDate(data.fecha),
      };
      await core.tickets.updateTicket(Number(data.idticket), payload);
      return {
        updated: true,
        unique: true,
        validated: false,
      };
    } catch (error) {
      return {
        updated: false,
        unique: false,
        validated: false,
      };
    }
  }
};
